<template>
  <div v-if="exam" class="container">
    <div>
      <Breadcrumb>
        <template slot="restPage">
          <el-breadcrumb-item>
            <router-link :to="{ name: 'Browse' }">
              Browse
            </router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <router-link :to="{ name: 'Examination', query: { examId } }">
              {{ testName }}
            </router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <router-link
              :to="{ name: 'Questions', query: { examId, sectionId } }"
            >
              {{ sectionName }}
            </router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <kbd>{{ question.order }}</kbd>
          </el-breadcrumb-item>
        </template>
      </Breadcrumb>
    </div>
    <MultipleChoice
      v-if="question.type === 'default' && !question.passage"
      mode="explanation"
      :questionOrder="String(question.order)"
      :content="question.content"
      :options="question.options"
      :isCorrect="false"
      correctAnswer="C"
      :explanation="question.exp"
    />
    <MultipleChoiceWithPassage
      v-if="question.type === 'default' && question.passage"
      mode="explanation"
      :questionOrder="String(question.order)"
      :content="question.content"
      :passageIntro="question.passage.intro"
      :passageContent="question.passage.content"
      :options="question.options"
      correctAnswer="C"
      :explanation="question.exp"
    />
    <Math
      v-else-if="question.type === 'math'"
      mode="explanation"
      :questionOrder="String(question.order)"
      :content="question.content"
    />
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import satApi from "@/apis/sat.js";

export default {
  metaInfo() {
    return {
      title: "Explanation - " + this.CompanyName
    };
  },

  components: {
    Breadcrumb
  },
  computed: {
    examId() {
      return this.exam.id;
    },
    sectionId() {
      return this.exam.subjects[0].id;
    },
    testName() {
      return this.exam.full_name;
    }
  },
  data() {
    return {
      exam: null,
      sectionName: "",
      question: {},
      question_detail: {},
      question_tags: [],
      userAnswer: null
    };
  },
  async mounted() {
    if (this.$route.query.answerId > 0) {
      this.getUserAnswer(this.$route.params.answerId);
    }
    const question = await satApi.getQuestion(this.$route.query.questionId);
    this.question = question.sat_question;
    this.exam = question.sat;
    this.sectionName = "Reading";
  },

  methods: {
    async getUserAnswer(answerId) {
      const res = await satApi.getUserAnswer(answerId);
      this.userAnswer = res.user_exam_answer;
    }
  }
};
</script>

<style scoped>
.container {
  padding-top: 0px;
  margin-bottom: 20px;
}
</style>