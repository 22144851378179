<template>
  <div class="breadcrumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        v-if="
          $route.matched &&
            $route.matched.length > 0 &&
            ($route.matched[0].name === 'Admin' ||
              $route.matched[0].name === 'Teacher' || $route.matched[0].name === 'Company')
        "
      >
        <router-link :to="{ name: $route.matched[0].name }">
          <span v-if="$route.matched[0].name === 'Admin'">
            {{ $t("header.admin") }}
          </span>
          <span v-if="$route.matched[0].name === 'Teacher'">
            {{ $t("header.teacher") }}
          </span>
          <span v-if="$route.matched[0].name === 'Company'">
            {{ $t("header.company") }}
          </span>
        </router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item
        :key="breadcrumb.name"
        v-for="breadcrumb in breadcrumbList"
      >
        <router-link :to="routeTo(breadcrumb)">
          {{ breadcrumb.text || $t(`pageTitle.${breadcrumb.name}`) }}
        </router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="this.$slots.lastPage">
        <slot name="lastPage"></slot>
      </el-breadcrumb-item>
      <slot name="restPage" />
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props: ["customBreadcrumbList"],
  data() {
    return { breadcrumbList: [] };
  },
  mixins: [],
  watch: {
    customBreadcrumbList() {
      this.updateBreadcrumb();
    },
    $route() {
      this.updateBreadcrumb();
    }
  },
  created() {
    this.updateBreadcrumb();
  },
  methods: {
    routeTo(breadcrumb) {
      if (breadcrumb.path) {
        return { path: breadcrumb.path };
      }
      return { name: breadcrumb.name };
    },
    updateBreadcrumb() {
      this.breadcrumbList = this.filterRole(
        this.customBreadcrumbList || this.$route.meta.breadcrumb
      );
    },
    filterRole(breadcrumbList) {
      // if (this.isRoleAdmin()) return breadcrumbList;
      return (breadcrumbList || []).filter(
        breadcrumb => breadcrumb.name !== "adminHome"
      );
    }
  }
};
</script>

<style scoped>
.breadcrumb {
  /* margin: 1.8rem 0; */
  /* margin-bottom: 1.8rem; */
}
::v-deep .el-breadcrumb {
  font-size: 16px;
}

@media screen and (max-width: 736px) {
  ::v-deep .el-breadcrumb {
    font-size: 12px;
  }
  ::v-deep .el-breadcrumb__separator {
    margin: 0;
  }
}
</style>
